<template>
    <div>
        <v-row align="end" justify="end">
            <v-col cols="12" sm="12" md="6" lg="4">
                <el-input suffix-icon="el-icon-search" v-model="search" clearable placeholder="Search">
                </el-input>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col cols="12">
                <v-data-table :search="search" :loading="loading" :items="regions" :headers="headers"
                    style="cursor: pointer;" @click:row="routeToRegion">
                    <!-- <template v-slot:[`item.action`]="{ item }">
                    <v-btn @click="routeOrganisation(item)" class="mx-1" x-small fab color="primary"><v-icon>edit</v-icon></v-btn>
                    <v-btn @click="removeOrganisation(item)" class="mx-1" x-small fab color="danger"><v-icon>delete_outline</v-icon></v-btn>
                </template> -->
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data: () => ({
        headers: [
            {
                text: 'Code',
                value: 'code',
                align: 'center',
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center',
            }
        ],
        loading: false,
        regions: [],
        search: ''
    }),
    created() {
        this.getRegions();
    },
    methods: {
        async getRegions() {
            this.loading = true
            this.regions = await this.$API.getRegions()
            this.loading = false
        },
        routeToRegion(item) {
            this.$router.push({ path: `/region/${item.id}` })
        }
    }
}
</script>